import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styled from "@emotion/styled";
import { Divider } from "@material-ui/core";

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ThanksMessage = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: limegreen;
  margin-bottom: 20px;
`;

export default ({ isOpen, onContribute, onClose, item, amountContributed }) => {
  const [name, setName] = useState();
  const [amount, setAmount] = useState();
  const [message, setMessage] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const data = {
      itemId: item.id,
      name,
      amount,
      message
    };

    await onContribute(data);

    setIsSubmitting(false);
    setAmount(undefined);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {item.name}
      </DialogTitle>
      <DialogContent>
        {amountContributed ? (
          <ThanksMessage>
            Thank you for your contribution of ${amountContributed}!
          </ThanksMessage>
        ) : (
          <DialogContentText>
            Thank you so much!! Please use one of the transfer methods below.
          </DialogContentText>
        )}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="venmo"
          >
            <Typography>Venmo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ParagraphContainer>
              <Typography>
                Katherine's Venmo handle is @katherine-bernstein-1
              </Typography>
              <Typography>
                Drew's Venmo handle is @Drew-Bailey
              </Typography>
            </ParagraphContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="zelle"
          >
            <Typography>Zelle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can send through Zelle to our shared email: drewkatbaileybernstein@gmail.com
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="paypal"
          >
            <Typography>Paypal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can send via PayPal at <a href="https://paypal.me/katbernstein" target="_blank">https://paypal.me/katbernstein</a>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <br />
        <Divider />
        <br />

        <DialogContentText>
          Please submit your name and the amount you've sent (or plan to send) so we know who
          to thank, and so that the website reflects your contribution.
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Your Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="Note (optional!)"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} disabled={!name || !amount || isSubmitting} color="secondary">
          Submit
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
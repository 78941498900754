import React from "react";
import styled from "@emotion/styled";

const WIDTH = 200;

const Bar = styled.div`
  height: 6px;
  width: ${WIDTH}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #E8E8E8;
  z-index: 1;
`;

const Progress = styled.div`
  background-color: limegreen;
  z-index: 2;
  height: 6px;
  width: ${({ width }) => width}px;
`;

export default ({ current, total }) => {
  let pct = current / total;
  if (pct < 0) {
    pct = 0;
  }
  if (pct > 1) {
    pct = 1;
  }

  return (
    <Bar>
      <Progress width={pct * WIDTH} />
    </Bar>
  )
}
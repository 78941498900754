import axios from "axios";

const PROD_BASE_URL = "https://servo.drewkatregistry.com";
const LOCAL_BASE_URL = "http://192.168.1.60:8081"

const baseUrl = () => {
  return window.location.origin.includes("localhost") ? LOCAL_BASE_URL : PROD_BASE_URL;
}

export const loadRegistryData = async () => {
  const req = await axios.get(`${baseUrl()}/registry`);
  return req.data;
}

export const submitContributionToRegistry = async (data) => {
  await axios.post(`${baseUrl()}/registry/contribute`, data);
}

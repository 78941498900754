import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from '@material-ui/core/Button';
import ProgressBar from "./ProgressBar";
import ContributeModal from "./ContributeModal";
import { getAmountContributedFromLocalStorage } from "../utils";

const MOBILE_WIDTH = 850;

const Container = styled.div`
  height: fit-content;
  min-height: 40vh;
  position: relative;
  padding: 30px;
  
  &:nth-child(even) {
    background-color: #FFF5EE;
    color: #36454F;
    &::before {
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  120' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 88L50 83C100 77 200 66 300 45C400 23 500 -9 600 2C700 12 800 66 900 88C1000 110 1100 99 1150 93L1200 88V120H1150C1100 120 1000 120 900 120C800 120 700 120 600 120C500 120 400 120 300 120C200 120 100 120 50 120H0V88Z' fill='%2320B2AA'/></svg>");
    }
    
    span.MuiButton-label {
      color: #36454F !important;
    }
    
    button.MuiButtonBase-root {
      border-color:#36454F !important;
      width: 100% !important;
    }
  }
  &:nth-child(odd) {
    background-color: #20B2AA;
    color: white;
    &::before {
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  120' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 88L50 83C100 77 200 66 300 45C400 23 500 -9 600 2C700 12 800 66 900 88C1000 110 1100 99 1150 93L1200 88V120H1150C1100 120 1000 120 900 120C800 120 700 120 600 120C500 120 400 120 300 120C200 120 100 120 50 120H0V88Z' fill='%23FFF5EE'/></svg>");
    }
    
    span.MuiButton-label {
      color: #FFF5EE !important;
    }
    
    button.MuiButtonBase-root {
      border-color: #FFF5EE !important;
      width: 100% !important;
    }
  }
  
  &::before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 27vw bottom;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 50px;
  
  @media (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column;
    align-items: center;
  }
  
  img {
    width: 400px;
    object-fit: cover;
    border-radius: 5px;
    
    @media (max-width: ${MOBILE_WIDTH}px) {
      margin-bottom: 15px;
    }
  }
`;

const ItemDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  
  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-left: 0;
    align-items: center;
  }
  
  h2 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
  }
`;

const WebsiteLink = styled.a`
  font-style: italic;
  color: inherit;
  margin-top: 20px;
  font-weight: 300;
`;

const ProgressSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`;

const ProgressText = styled.div`
  margin-left: 15px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const GoalCompleteText = styled.div`
  color: darkgreen;
  font-style: italic;
  font-weight: bold;
  text-align: center;
`;

const CYA_COST = 10000;

export default ({ item, onContribute }) => {
  const { id, name, description, imageLink, website, cost, amountReceived } = item;

  const [isOpen, setIsOpen] = useState(false);

  const isComplete = cost <= amountReceived;
  let progressText = isComplete ? "Complete!" : `$${amountReceived} of $${cost} received`;
  if (cost >= CYA_COST) {
    progressText = `$${amountReceived} received!`;
  }

  const amountContributed = getAmountContributedFromLocalStorage(item);

  const buttonText = amountContributed
    ? `Thank you for your contribution of $${amountContributed} to this!`
    : "Want to contribute to this?";

  const goalCompleteText = amountContributed
    ? `We've reached our goal for this, and we're so excited! Thank you for your contribution of $${amountContributed}!`
    : "We've reached our goal for this, and we're so excited!";

  return (
    <Container>
      <ItemWrapper>
        <img src={imageLink} alt={`Image of ${name}`} />
        <ItemDescription>
          <h2>{name}</h2>
          <ProgressSection>
            <ProgressBar current={amountReceived} total={cost} />
            <ProgressText>{progressText}</ProgressText>
          </ProgressSection>
          <div>{description}</div>
          {cost < CYA_COST && (
            <WebsiteLink href={website} target="_blank">
              Check out their website here!
            </WebsiteLink>
          )}
          <ButtonWrapper>
            {isComplete ? (
              <GoalCompleteText>{goalCompleteText}</GoalCompleteText>
            ) : (
              <Button variant="outlined" onClick={() => setIsOpen(true)}>
                {buttonText}
              </Button>
            )}
          </ButtonWrapper>
          <ContributeModal
            item={item}
            isOpen={isOpen}
            onContribute={onContribute}
            onClose={() => setIsOpen(false)}
            amountContributed={amountContributed}
          />
        </ItemDescription>
      </ItemWrapper>
    </Container>
  )
}
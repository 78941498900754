import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
  
  &::before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: -0.4%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 27vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  120' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 88L50 83C100 77 200 66 300 45C400 23 500 -9 600 2C700 12 800 66 900 88C1000 110 1100 99 1150 93L1200 88V120H1150C1100 120 1000 120 900 120C800 120 700 120 600 120C500 120 400 120 300 120C200 120 100 120 50 120H0V88Z' fill='%2320B2AA'/></svg>");
  }
`;

const Paragraph = styled.div`
  max-width: 800px;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
`;

export default () => (
  <Container>
    <Paragraph>Hello friend and/or family!</Paragraph>
    <Paragraph>
      Gifts for our wedding are <b><i>extremely optional</i></b>. Flying to Italy for multiple days is very
      expensive, and we certainly don't expect you to spend anything extra. Just having you join us is more than
      enough of a gift!
    </Paragraph>
    <Paragraph>
      That said, several people have asked about a registry, so we put this together.
    </Paragraph>
    <Paragraph>
      If you would like to give us a gift, we would love for you to contribute to some experiences we're
      hoping to have on our honeymoon! We've put together some activities we're hoping to do, along with the
      rough cost of those activities, but you can choose any amount to contribute.
    </Paragraph>
    <Paragraph>
      We're doing this very low-tech, so we've included our Venmo/Zelle/Paypal info. If you have any
      questions, you have our contact info!
    </Paragraph>
  </Container>
)
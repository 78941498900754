import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  padding: 30px;
  background-color: #20B2AA;
  color: white;
  font-family: Parisienne;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
`;

export default () => (
  <Container>
    <div>Drew & Katherine's Wedding Registry</div>
  </Container>
)
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Header from "./components/Header";
import IntroMessage from "./components/IntroMessage";
import Item from "./components/Item";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { sortRegistry, storeContribution } from "./utils";
import { loadRegistryData, submitContributionToRegistry } from "./requests";

function App() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState([]);

  const loadData = async () => {
    const registryData = await loadRegistryData();

    setData(sortRegistry(registryData));
  }

  const onContribute = async (contributionData) => {
    await submitContributionToRegistry(contributionData);
    await loadData();

    storeContribution(contributionData);
    setSnackbarOpen(true);
  }

  useEffect(() => {
    loadData();
  }, []);

  const renderItem = (item) =>  (
    <Item key={item.id} item={item} onContribute={onContribute} />
  )

  const incompleteItems = data.filter((item) => item.amountReceived < item.cost);
  const completeItems = data.filter((item) => item.amountReceived >= item.cost);

  return (
    <React.Fragment>
      <Header />
      <IntroMessage />
      {incompleteItems.map(renderItem)}
      {completeItems.map(renderItem)}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity="success">
          Thank you for your gift!
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}

export default App;

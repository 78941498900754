const LOCAL_STORAGE_KEY = "kdregistry_contributions";

const LOCATIONS = [
  "Positano",
  "Nerano",
  "Athens",
  "Paros",
  "Santorini",
  "Crete",
  "Chios",
  "Alacati",
  "Istanbul",
  "Choose Your Own Adventure"
];

// Localhost storage helpers

const getStoredContributions = () => {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
}

const setStoredContributions = (contributions) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(contributions));
}

export const getAmountContributedFromLocalStorage = (item) => {
  const contributions = getStoredContributions();
  return contributions[item.id] || 0;
}

export const storeContribution = (contributionData) => {
  const { itemId, amount } = contributionData;

  const contributions = getStoredContributions();
  contributions[itemId] = (contributions[itemId] || 0) + Number.parseFloat(amount);

  setStoredContributions(contributions);
}

// Registry data sorting logic

const getIndexForItem = (item) => {
  let itemIdx = 100;
  LOCATIONS.forEach((location, idx) => {
    if (item.name.includes(location)) {
      itemIdx = idx;
    }
  });
  return itemIdx;
}

const compareFn = (num1, num2) => {
  if (num1 < num2) {
    return -1;
  }

  if (num1 > num2) {
    return 1;
  }

  return 0;
};

export const sortRegistry = (registryData) => {
  const groups = {}
  LOCATIONS.forEach((location, idx) => {
    groups[idx] = [];
  });

  registryData.forEach((item) => {
    const itemIdx = getIndexForItem(item);
    const items = groups[itemIdx] || [];
    items.push(item);
    groups[itemIdx] = items;
  });

  return Object.entries(groups)
    .sort((entry1, entry2) => compareFn(entry1[0], entry2[0]))
    .map((entry) => entry[1].sort((item1, item2) => compareFn(item1.cost, item2.cost)))
    .flat();
}